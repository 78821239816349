/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./Register.css";
import { API_URL } from "../utils/constants";
import Snackbar from "../Components/SnackBar";
import { useErrorRenderer } from "../Hooks/useErrorRenderer";

const Register = ({ switchForm }) => {
  const [message, setMessage, isError, setIsError, resetErrorState] =
    useErrorRenderer();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [organization, setOrganization] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const sendObject = JSON.stringify({
        email,
        password,
        fullName,
        organization,
      });

      const response = await fetch(`${API_URL}/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: sendObject,
      });
      const data = await response.json();
      if (response.status === 200) {
        switchForm("login");
        // await fetch("http://localhost:4243/create-checkout-session",
        // {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },

        // },
        // ).then(response2 => response2.json())
        // .then(data => {

        // window.location.href = data;
        // });
      } else {
        setMessage(data.message);
        setIsError(true);
        resetErrorState();
      }
    } catch (error) {
      setMessage(error.message);
      setIsError(true);
      resetErrorState();
    }
  };

  return (
    <div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <p className="seymourText">Seymour</p>
        <h2 className="mt-2 text-center text-2xl font-extrabold text-white">
          Closed Captions for Live Events
        </h2>
        <p className="closedCaption2">Sign Up</p>
        <form onSubmit={handleRegister}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-white"
            >
              Full Name
            </label>
            <div className="mt-2">
              <input
                id="fullName"
                name="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                autoComplete="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="mt-2">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-white"
            >
              Organization
            </label>
            <div className="mt-2">
              <input
                id="fullName"
                name="fullName"
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="mt-2">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-white"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="fullName"
                name="fullName"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="mt-2">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-white"
            >
              Password
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="my-4 magicLink">
            We’ll send a magic link to your inbox to complete your registration.
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign Up
            </button>
          </div>
          <p className="mt-10 text-center text-sm text-gray-500">
            Already Have an account?{" "}
            <a
              onClick={() => {
                switchForm("login");
              }}
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign In
            </a>
          </p>
        </form>
        <Snackbar isError={isError} message={message} />
      </div>
    </div>
  );
};

export default Register;
