import React, { useEffect, useState, useRef } from "react";
import SeymourText from "../Components/SeymourText";
import { useEventResult } from "../State/EventResultContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "./SoundCheck.css";
import StepIndicator from "../Components/StepIndicator";
import Navbar from "../Components/Navbar";
import { LiveAudioVisualizer } from "react-audio-visualize";
import {
  API_SOCKET_URL,
  DEEPGRAM_SOCKET_URL,
  DEEPGRAM_API_TOKEN,
} from "../utils/constants";
import { useErrorRenderer } from "../Hooks/useErrorRenderer";
import { useLoginResult } from "../State/LoginResultContext";

function EventDetail(props) {
  const [mediaRecorder, setMediaRecorder] = useState();
  const { loginResults } = useLoginResult();
  const visualizerRef = useRef(null);
  const { eventResults } = useEventResult();
  const websocketRef = useRef(null);
  const deepGramSocketRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [status, setStatus] = useState("Not Connected");
  const [isStreamingMode, setIsStreamingMode] = useState(false);
  const [, setMessage, , setIsError, resetErrorState] = useErrorRenderer();
  const navigate = useNavigate();
  const [currentTimeInSecond, setCurrentTimeInSecond] = useState(0);

  useEffect(() => {
    const startRecording = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        const audioBlob = new Blob([event.data], { type: "audio/wav" });
        visualizerRef.current.setBlob(audioBlob);
      };
      recorder.start();
      setMediaRecorder(recorder);
    };
    startRecording();
  }, []);

  useEffect(() => {
    if (eventResults.id) {
      websocketRef.current = new WebSocket(API_SOCKET_URL, [
        "event",
        eventResults.id,
      ]);
      websocketRef.current.onopen = () => {};
      websocketRef.current.onmessage = (message) => {
        const received = JSON.parse(message.data);
        if (received.type === "transcript") {
          if (document.getElementById("numConnected")) {
            document.getElementById(
              "numConnected"
            ).innerHTML = `Attendees Connected: ${received.numConnected || 0}`;
          }
        }
      };

      websocketRef.current.onclose = () => {
        setStatus("Not Connected");
      };

      websocketRef.current.onerror = (error) => {
        setMessage(error.message);
        setIsError(true);
        resetErrorState();
      };
    }
    // eslint-disable-next-line
  }, [eventResults?.id]);

  const startRecording = () => {
    const isNova2Model = true;
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        if (!MediaRecorder.isTypeSupported("audio/webm")) {
          setMessage("Browser not supported");
          setIsError(true);
          resetErrorState();
          return;
        }
        setIsStreamingMode(true);
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: "audio/webm",
        });
        let dpmUrl = `${DEEPGRAM_SOCKET_URL}?punctuate=true&diarize=true`;
        if (eventResults.keywords && eventResults.keywords.length > 0) {
          const keywordArr = eventResults.keywords.split(" ");
          for (let i = 0; i < keywordArr.length; i++) {
            dpmUrl += `&keywords=${keywordArr[i]}`;
          }
        }
        const streamUrl = `${dpmUrl}${isNova2Model ? "&model=nova-2-ea" : ""}`;
        deepGramSocketRef.current = new WebSocket(streamUrl, [
          "token",
          DEEPGRAM_API_TOKEN,
        ]);

        setInterval(() => {
          setCurrentTimeInSecond((prev) => prev + 1);
        }, 1000);

        deepGramSocketRef.current.onopen = () => {
          setStatus("Connected");
          mediaRecorderRef.current.addEventListener(
            "dataavailable",
            async (event) => {
              if (
                event.data.size > 0 &&
                deepGramSocketRef.current.readyState === 1
              ) {
                deepGramSocketRef.current.send(event.data);
              }
            }
          );
          mediaRecorderRef.current.start(1000);
        };

        deepGramSocketRef.current.onmessage = (message) => {
          const received = JSON.parse(message.data);
          const transcript = received?.channel?.alternatives[0]?.transcript;
          const speaker = `Speaker${
            received?.channel?.alternatives[0]?.words[0]?.speaker + 1
          }`;
          if (transcript && received.is_final) {
            websocketRef.current.send(
              JSON.stringify({
                type: "transcript",
                transcript,
                eventId: eventResults.id,
                speaker,
                organizationId: loginResults.id,
                eventName: eventResults.name,
                eventLocation: eventResults.location,
              })
            );
          }
        };

        deepGramSocketRef.current.onclose = () => {};
        deepGramSocketRef.current.onerror = (error) => {
          setMessage(error.message);
          setIsError(true);
          resetErrorState();
        };
      })
      .catch((error) => {
        setMessage(error.message);
        setIsError(true);
        resetErrorState();
      });
  };

  const stopRecording = () => {
    websocketRef.current.send(
      JSON.stringify({ type: "endstream", eventId: eventResults.id })
    );
    mediaRecorderRef.current.stop();
    setIsStreamingMode(false);
    navigate("/my-events");
  };
 
  function formatTime(currentTimeInSecond) {
    const minutes = Math.floor(currentTimeInSecond / 60);
    const seconds = currentTimeInSecond % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  }
  
  return (
    <div>
      <Navbar />
      <div className="row">
        <StepIndicator currentStep={3} />
      </div>
      <div className="eventInfo1">
        <SeymourText props={"Check Live Audio"} />
      </div>

      <div className="mt-4 text-white text-center">
        <p>
          <span className="text-2xl">{eventResults.name}</span>
        </p>
        <p>
          <span className="text-2xl">{eventResults.location}</span>
        </p>
      </div>
      <div className="mt-4">
        <center>
          <button
            style={{ background: "#0d6fd8" }}
            className="linkCopy"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://seymour.events/` + eventResults.id
              );
              const popup = document.createElement("div");
              popup.innerText = "Link copied to clipboard!";
              popup.style.position = "fixed";
              popup.style.bottom = "10px";
              popup.style.left = "50%";
              popup.style.transform = "translateX(-50%)";
              popup.style.padding = "10px";
              popup.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
              popup.style.color = "#ffffff";
              popup.style.borderRadius = "5px";
              popup.style.zIndex = "9999";
              document.body.appendChild(popup);
              setTimeout(() => {
                document.body.removeChild(popup);
              }, 2000);
            }}
          >
            <span>
              <FontAwesomeIcon icon={faLink} style={{ color: "#ffffff" }} />
            </span>
            Copy link
          </button>
        </center>
      </div>
      {!isStreamingMode && (
        <>
          <div className="inputDevice text-center ">
            <SeymourText props={"Input Monitor"} className="text-center" />
          </div>
          <div className="flex justify-center">
            {mediaRecorder && (
              <LiveAudioVisualizer
                mediaRecorder={mediaRecorder}
                width={200}
                height={75}
              />
            )}
          </div>
        </>
      )}

      {isStreamingMode ? (
        <div className="mt-4">
          <div className="text-center">
            <p id="status">{status}</p>
            <p id="numConnected"></p>
            <p id="duration">Duration: {formatTime(currentTimeInSecond)}</p>
            <p id="transcript"></p>
          </div>
          <div className="mt-4 col-span-1 flex justify-center">
            <button
              className=" !bg-red-600 w-full sm:max-w-screen-md p-4 rounded-lg"
              id="stopButton"
              onClick={stopRecording}
            >
              <div>Stop Speech to Text</div>
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-4 col-span-1 flex justify-center">
          <button
            className=" !bg-green-600 w-full sm:max-w-screen-md p-4 rounded-lg"
            id="startButton"
            onClick={startRecording}
          >
            <div>Start Speech to Text</div>
          </button>
        </div>
      )}
    </div>
  );
}

export default EventDetail;
